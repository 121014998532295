import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Pagination from '@mui/material/Pagination';
import Skeleton from '@mui/material/Skeleton';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { format } from 'date-fns';
import orderBy from 'lodash/orderBy';
import React from 'react';
import { DisplayMarkup } from '../../../components/display-markup';
import { ContentCategoryName, ContentDocument } from '../../../types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    head: {
      fontSize: '40px',
      fontFamily: 'Bebas Neue',
      fontWeight: 'bold',
      color: 'black',
      marginBottom: '.5em',
    },
    imageStyle: {
      boxShadow: '2px 2px 5px gray',
      height: '189px',
      width: '100%',
    },
    newsHeader: {
      color: ' #0f0f0f',
      fontSize: ' 1.70em',
      fontWeight: 'bold',
      fontFamily: 'Lato',
      lineHeight: 'normal',
      minHeight: '60px',
      marginBottom: '5px',
      marginTop: '5px',
      paddingRight: '20px',
    },
    newsDescription: {
      fontSize: '15px',
      fontFamily: 'Lato',
      minHeight: '130px',
      paddingRight: '10px',
      overflow: 'hidden',
      paddingTop: '-10px',
    },
    newsDate: {
      color: '#555',
      fontSize: '12px',
      fontWeight: 'bold',
      fontFamily: 'Lato',
      marginTop: '5px',
      paddingBottom: '10px',
    },
    sectionHeight: {
      [theme.breakpoints.up('sm')]: { minHeight: '880px' },
    },
    markup: {
      '& p': {
        margin: 0,
      },
    },
    showLessMarkup: {
      WebkitLineClamp: 6,
      overflow: 'hidden',
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      '& p': {
        margin: 0,
      },
    },
  }),
);

// Read More Function
function ShowMore(props: { id: number; title: string | null; desc: string }) {
  // State
  const [readMore, setReadMore] = React.useState(true);
  // Hooks
  const classes = useStyles();

  return (
    <div>
      <div className={classes.newsDescription}>
        {props.title && <div className={classes.newsHeader}>{props.title}</div>}
        <DisplayMarkup
          markup={props.desc}
          className={!readMore ? classes.markup : classes.showLessMarkup}
        />
      </div>

      <Button
        size="small"
        color="secondary"
        onClick={() => setReadMore(!readMore)}
        style={{
          float: 'right',
          marginRight: 25,
          textTransform: 'none',
          textDecoration: 'underline',
        }}
      >
        {readMore ? 'Read More' : 'Close'}
      </Button>
    </div>
  );
}

export function News(props: { content: ContentDocument[]; loading: boolean }) {
  // Props
  const { content, loading } = props;
  // State
  const [newPage, setNewPage] = React.useState<number>(1);
  const [itemsPerPage] = React.useState<number>(4);
  // Hooks
  const classes = useStyles();

  const dataFiltered = content.filter(
    (c) => c.Category === ContentCategoryName['News & Notes'],
  );
  const data = orderBy(dataFiltered, 'DdocIndex');

  // Pagination
  const handlePage = (e: React.ChangeEvent<unknown>, page: number) => {
    setNewPage(page);
  };

  // Sort Items
  const indexOfLastItem = newPage * itemsPerPage;
  const indexofFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexofFirstItem, indexOfLastItem);

  // Create Sorted Item Element
  const displayNews = currentItems.map(
    ({
      DdocId,
      Title,
      DateCreated,
      Description,
      ThumbnailName,
      ThumbnailContent,
    }) => {
      const formatDate = format(new Date(DateCreated || ''), 'MMMM dd, yyyy');

      return (
        <Grid item xs={12} sm={6} key={DdocId}>
          <img
            src={ThumbnailName || ThumbnailContent || undefined}
            alt={Title || undefined}
            className={classes.imageStyle}
          />

          {Description ? (
            <ShowMore id={DdocId} title={Title} desc={Description} />
          ) : null}

          <div className={classes.newsDate}>{formatDate}</div>
        </Grid>
      );
    },
  );

  // Loading Placeholders
  let skeletons = [];
  for (var i = 0; i < 4; i++) {
    skeletons.push(
      <Grid key={i} item xs={12} sm={6} style={{ marginBottom: '16px' }}>
        <Skeleton
          variant="rectangular"
          height={189}
          style={{ marginBottom: '10px', boxShadow: '2px 2px 5px gray' }}
        />
        <Skeleton height={60} className={classes.newsHeader} />
        <div
          className={classes.newsDescription}
          style={{ marginBottom: '10px' }}
        >
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </div>

        <Skeleton width="40%" />
      </Grid>,
    );
  }

  return (
    <Grid container>
      <div className={classes.head}>
        {ContentCategoryName['News & Notes'].toUpperCase()}
      </div>
      <Grid item xs={12}>
        <Grid container justifyContent="center">
          {!loading && data.length ? (
            <Grid item xs={12}>
              <div className={classes.sectionHeight}>
                <Grid container spacing={2}>
                  {displayNews}
                </Grid>
              </div>
            </Grid>
          ) : (
            <Grid container spacing={2}>
              {skeletons.map((s) => {
                return s;
              })}
            </Grid>
          )}

          <Pagination
            count={Math.ceil(data.length / 4)}
            variant="outlined"
            color="secondary"
            shape="rounded"
            onChange={handlePage}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
