import CloseIcon from '@mui/icons-material/Close';
import AppBar from '@mui/material/AppBar';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import { API } from 'aws-amplify';
import React from 'react';
import { AppContext } from '../../contexts/app-context';
import { NotificationAction, NotificationText } from '../../types';
import { captureError } from '../../utils/capture-error';
import { toSafeDates } from '../../utils/formatter';
import { ActionList } from './action-list';
import { TextList } from './text-list';

const useStyles = makeStyles({
  container: {
    width: 360,
    display: 'flex',
    flexDirection: 'column',
  },
  navStatic: {
    backgroundColor: grey[900],
    height: 65,
    alignSelf: 'stretch',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: 10,
    paddingRight: 10,
    flexDirection: 'row',
    textDecoration: 'none',
  },
  listItem: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f1f1f1',
    },
  },
});

export function Notifications() {
  // Context
  const { state, dispatch } = React.useContext(AppContext);
  // State
  const [loading, setLoading] = React.useState(false);
  const [tabValue, setTabValue] = React.useState<number>(0);
  // Hooks
  const classes = useStyles();

  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const notificationsData: {
        texts: NotificationText[];
        actions: NotificationAction[];
      } = { texts: [], actions: [] };

      const textBody = {};
      const textPath = '/crm/contacts/notification/text';
      try {
        const responseText: {
          data: NotificationText[];
        } = await API.post('DashboardAPI', textPath, { body: textBody });
        notificationsData.texts = toSafeDates({ value: responseText.data });
      } catch (error) {
        captureError({ data: { error } });
      }

      const actionsBody = {};
      const actionsPath = '/crm/contacts/notification/actions';
      try {
        const responseActions: {
          data: NotificationAction[];
        } = await API.post('DashboardAPI', actionsPath, { body: actionsBody });
        notificationsData.actions = toSafeDates({
          value: responseActions.data,
        });
      } catch (error) {
        captureError({ data: { error } });
      }

      // Add notifications data to app-context
      dispatch({ type: 'SET_NOTIFICATIONS_DATA', payload: notificationsData });
      setLoading(false);
    };

    fetchData();
  }, [dispatch, state.notificationsOpen]);

  const { texts, actions } = state.notificationsData;
  // Unread Texts
  const unreadTexts = texts.filter((i) => !i.MsgRead);
  const textsLabel = unreadTexts.length
    ? `Texts (${unreadTexts.length})`
    : 'Texts';
  // Unread Actions
  const unreadActions = actions.filter((i) => i.Read === '0');
  const actionsLabel = unreadActions.length
    ? `Actions Needed (${unreadActions.length})`
    : 'Actions Needed';

  return (
    <Drawer
      open={state.notificationsOpen}
      anchor="right"
      variant="temporary"
      onClose={() => dispatch({ type: 'TOGGLE_NOTIFICATIONS_DRAWER' })}
    >
      <div className={classes.container} style={{ height: '100%' }}>
        {loading ? <LinearProgress /> : <div style={{ height: 4 }} />}

        <div style={{ paddingLeft: 10, display: 'flex', alignItems: 'center' }}>
          <IconButton
            onClick={() => dispatch({ type: 'TOGGLE_NOTIFICATIONS_DRAWER' })}
            aria-label="close notifications"
            size="large"
          >
            <CloseIcon fontSize="large" />
          </IconButton>

          <Typography variant="h6" color="primary" style={{ marginLeft: 10 }}>
            Notifications
          </Typography>
        </div>

        <AppBar
          component="div"
          position="static"
          elevation={0}
          style={{ backgroundColor: '#fefefe' }}
        >
          <Tabs
            value={tabValue}
            variant="fullWidth"
            textColor="primary"
            onChange={(event: React.ChangeEvent<{}>, newValue: number) => {
              setTabValue(newValue);
            }}
          >
            <Tab label={textsLabel} />
            <Tab label={actionsLabel} />
          </Tabs>
        </AppBar>

        <Divider />

        {tabValue === 0 ? <TextList /> : null}

        {tabValue === 1 ? <ActionList /> : null}
      </div>
    </Drawer>
  );
}
