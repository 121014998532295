import Close from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { TransitionProps } from '@mui/material/transitions';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { PopUpData } from '../../types';
import { captureError } from '../../utils/capture-error';
import { DisplayMarkup } from '../display-markup';
import { markPopUpViewed } from './pop-up-utils';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  text: {
    fontFamily: 'Lato',
    fontSize: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  markup: {
    '& p': {
      margin: 0,
    },
  },
});

export function PopUpItem(props: {
  data: PopUpData;
  username: string;
  onClose: () => void;
}) {
  // Props
  const { data, username } = props;
  // Hooks
  const classes = useStyles();

  const handleClose = async () => {
    try {
      await markPopUpViewed({ data, username });

      props.onClose();
    } catch (error) {
      captureError({ data: { error } });
    }
  };

  const imgPath = props.data.PopupFileName || props.data.PopupFilePath;

  const labelledby = 'display-popup-title';
  const describedby = 'display-popup-description';

  return (
    <Dialog
      TransitionComponent={Transition}
      maxWidth="sm"
      open
      onClose={handleClose}
      aria-labelledby={labelledby}
      aria-describedby={describedby}
      style={{ zIndex: 1301 }}
    >
      <div style={{ border: '2px solid black', borderRadius: 4 }}>
        <IconButton
          aria-label="close"
          size="small"
          style={{ float: 'right', margin: '5px 5px -15px 5px' }}
          onClick={handleClose}
        >
          <Close fontSize="small" />
        </IconButton>

        {data.Title && (
          <div>
            <DialogTitle>
              <Typography
                id={labelledby}
                variant="h6"
                style={{
                  fontFamily: 'Lato',
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                {data.Title}
              </Typography>
            </DialogTitle>
          </div>
        )}

        <Grid
          container
          alignItems="center"
          style={{ padding: '5px 25px 15px 25px' }}
        >
          {imgPath && (
            <Grid
              item
              xs={12}
              sm={data.Text ? 5 : 12}
              style={{ textAlign: 'center' }}
            >
              <img
                src={imgPath}
                alt={data.Title || ''}
                style={{ width: '100%', height: '100%' }}
              />
            </Grid>
          )}

          {data.Text && (
            <Grid item xs={12} sm={7} style={{ padding: '0px 15px' }}>
              <Typography component="div" className={classes.text}>
                <DisplayMarkup markup={data.Text} className={classes.markup} />
              </Typography>
            </Grid>
          )}

          {data.buttons?.map((button) => (
            <Grid
              key={button.label}
              item
              xs={12}
              style={{ textAlign: 'center', paddingTop: 5 }}
            >
              <Button
                variant="contained"
                size="large"
                component={button.url ? 'a' : 'button'}
                href={button.url}
                target="_blank"
                style={{
                  backgroundColor: blue[900],
                  color: '#fff',
                  margin: 10,
                  width: 170,
                  textTransform: 'none',
                }}
                onClick={handleClose}
              >
                {button.label}
              </Button>
            </Grid>
          ))}
        </Grid>
      </div>
    </Dialog>
  );
}
