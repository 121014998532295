import { Container, Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { RouteContainer } from '../../components/route-container';
import { ContentCategoryName, ContentDocument } from '../../types';
import { captureError } from '../../utils/capture-error';
import { ContestMeter } from './sections/contest-meter';
import { Leaderboards } from './sections/leaderboards';
import { News } from './sections/news-notes';
import { Speedometer } from './sections/speedometer';
import { Events } from './sections/upcoming-events';
import { WeeklyCalls } from './sections/weekly-calls';

export function Home() {
  // Query - Homepage Content
  const path = '/homepage/dashboard/document';
  const query = useQuery({
    queryKey: [path],
    queryFn: async () => {
      const response: {
        data: ContentDocument[];
      } = await API.post('DashboardAPI', path, {
        body: {
          Categories: [
            ContentCategoryName['Leaderboards'],
            ContentCategoryName['News & Notes'],
            ContentCategoryName['Upcoming Events'],
          ],
        },
      });

      if (response.data) {
        return response.data;
      }

      return [];
    },
    onError: (error) => captureError({ data: { error } }),
  });

  return (
    <RouteContainer routeTitle="Home" hideHeader>
      <Container>
        <Grid container spacing={3} style={{ paddingTop: '15px' }}>
          <Grid item sm={12} md={6}>
            <WeeklyCalls />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Speedometer />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <ContestMeter />
          </Grid>
          <Grid item sm={12} md={6}>
            <Leaderboards
              loading={query.isLoading}
              content={query.data || []}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <News loading={query.isLoading} content={query.data || []} />
          </Grid>
          <Grid item sm={12}>
            <Events loading={query.isLoading} content={query.data || []} />
          </Grid>
        </Grid>
      </Container>
    </RouteContainer>
  );
}
