import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { Auth } from 'aws-amplify';
import React from 'react';
import { AuthContext } from '../contexts/auth-context';

export function TokenError() {
  // Context
  const { state } = React.useContext(AuthContext);

  if (state.tokenError) {
    return (
      <Dialog open>
        <DialogTitle>Please Sign In again</DialogTitle>
        <DialogActions>
          <Button color="secondary" onClick={() => Auth.federatedSignIn()}>
            Sign in
          </Button>
        </DialogActions>
      </Dialog>
    );
  } else {
    return null;
  }
}
