import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Auth } from 'aws-amplify';
import React from 'react';
import { ReactComponent as ARCLogo } from '../assets/arc-logo.svg';
import { AuthContext } from '../contexts/auth-context';

const useStyles = makeStyles({
  container: {
    height: '100vh',
    width: '100vw',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoContainer: {
    backgroundColor: '#212121',
    height: 140,
    width: 140,
    borderRadius: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 10,
  },
  message: {
    maxWidth: 480,
    padding: 10,
  },
});

export function AuthorizeError() {
  // Context
  const { state } = React.useContext(AuthContext);
  // Hooks
  const classes = useStyles();

  const renderMessage = () => {
    switch (state.authorizeError) {
      case 'pending': {
        return (
          <Typography
            variant="body1"
            component="div"
            className={classes.message}
          >
            Your Alliance account is currently pending. You will have limited
            access to our websites until you log in to{' '}
            <a href="https://thealliancecontracting.com/">
              Alliance Contracting
            </a>{' '}
            and sign the Annual Agent Agreement. If you need further assistance,
            please reach out to your upline or the Help Desk(
            <a href="mailto:help@naaleads.com">help@naaleads.com</a>)
            <ul>
              <li>The Help Desk hours are Monday-Friday 8AM-5PM EST</li>
            </ul>
          </Typography>
        );
      }
      case 'inactive': {
        return (
          <Typography
            variant="body1"
            component="div"
            className={classes.message}
          >
            Your Alliance account is not able to be accessed at this time due to
            inactivity. Please contact your manager or the Help Desk (
            <a href="mailto:help@naaleads.com">help@naaleads.com</a>) for
            further assistance.
            <ul>
              <li>
                Please include your name, NAA Number, and the name of your
                upline/agency manager so that the Help Desk can better assist
                you.
              </li>
              <li>The Help Desk hours are Monday-Friday 8AM-5PM EST</li>
            </ul>
          </Typography>
        );
      }
      case 'unauthorized': {
        return (
          <Typography
            variant="body1"
            component="div"
            className={classes.message}
          >
            Your Alliance account is not able to be accessed at this time.
            Please contact your manager or the Help Desk (
            <a href="mailto:help@naaleads.com">help@naaleads.com</a>) for
            further assistance.
            <ul>
              <li>
                Please include your name, NAA Number, and the name of your
                upline/agency manager so that the Help Desk can better assist
                you.
              </li>
              <li>The Help Desk hours are Monday-Friday 8AM-5PM EST</li>
            </ul>
          </Typography>
        );
      }
      case 'terminated': {
        return (
          <Typography
            variant="body1"
            component="div"
            className={classes.message}
          >
            Access has been denied.
          </Typography>
        );
      }
      default: {
        return (
          <Typography
            variant="body1"
            component="div"
            className={classes.message}
          >
            Your Alliance account is not able to be accessed at this time.
            Please refresh the page and try again. If the problem persists
            contact the Help Desk (
            <a href="mailto:help@naaleads.com">help@naaleads.com</a>) for
            further assistance.
            <ul>
              <li>The Help Desk hours are Monday-Friday 8AM-5PM EST</li>
            </ul>
          </Typography>
        );
      }
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.logoContainer}>
        <ARCLogo />
      </div>

      {renderMessage()}

      <Button
        variant="contained"
        color="secondary"
        size="large"
        style={{ marginTop: 10 }}
        onClick={() => Auth.signOut()}
      >
        Sign out
      </Button>
    </div>
  );
}
