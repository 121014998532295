import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../contexts/app-context';
import { AuthContext } from '../../contexts/auth-context';
import { NavDataSection } from './nav-data';
import { NavSection } from './nav-section';

const useStyles = makeStyles({
  container: {
    width: 320,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  section: {
    paddingLeft: 10,
    paddingTop: 4,
    paddingBottom: 4,
    display: 'flex',
    alignItems: 'center',
  },
});

export function NavDrawer(props: { data: NavDataSection[] }) {
  // Context
  const { dispatch, state } = React.useContext(AppContext);
  const {
    state: { user },
  } = React.useContext(AuthContext);
  // Hooks
  const classes = useStyles();

  return (
    <Drawer
      open={state.drawerOpen}
      anchor="left"
      variant="temporary"
      onClose={() => dispatch({ type: 'TOGGLE_DRAWER' })}
    >
      <div className={classes.container}>
        <div className={classes.section}>
          <IconButton
            onClick={() => dispatch({ type: 'TOGGLE_DRAWER' })}
            aria-label="close menu"
            size="large"
          >
            <CloseIcon fontSize="large" />
          </IconButton>

          <Typography variant="h6" color="primary" style={{ marginLeft: 10 }}>
            Menu
          </Typography>
        </div>

        <Divider />

        <div style={{ flex: 1, overflowY: 'scroll' }}>
          {props.data.map((item) => (
            <NavSection key={item.title} item={item} isMobile />
          ))}
        </div>

        <Divider />

        <Link to="/account/info" style={{ textDecoration: 'none' }}>
          <div className={classes.section}>
            <IconButton aria-label="go to account" size="large">
              <AccountCircleIcon fontSize="large" color="primary" />
            </IconButton>

            <Typography variant="h6" color="primary" style={{ marginLeft: 10 }}>
              {user.username}
            </Typography>
          </div>
        </Link>
      </div>
    </Drawer>
  );
}
