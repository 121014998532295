import MessageIcon from '@mui/icons-material/Message';
import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { API } from 'aws-amplify';
import formatDistance from 'date-fns/formatDistance';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../contexts/app-context';
import { NotificationText } from '../../types';
import { captureError } from '../../utils/capture-error';
import { toPhoneNumber } from '../../utils/formatter';

const useStyles = makeStyles({
  listItem: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f1f1f1',
    },
  },
});

export function TextListItem(props: { item: NotificationText }) {
  // Context
  const { dispatch } = React.useContext(AppContext);
  // Props
  const { item } = props;
  // State
  const [updating, setUpdating] = React.useState(false);
  // Hooks
  const classes = useStyles();
  const navigate = useNavigate();

  const handleClick = async () => {
    const body = { CMId: item.CMId };
    const path = '/crm/contacts/notification/texts/markRead';
    try {
      setUpdating(true);
      // Mark the text notification as read
      await API.post('DashboardAPI', path, { body });
      // Make sure there is no impersonated agent for when the CRM loads
      dispatch({ type: 'IMPERSONATE_AGENT', payload: null });
      // Route to the CRM with ContactId in the url
      navigate(`/business/crm?ContactId=${item.ContactId}`);
      // Close the drawer
      dispatch({ type: 'TOGGLE_NOTIFICATIONS_DRAWER' });
    } catch (error) {
      setUpdating(false);
      captureError({ data: { error, request: { body, path } } });
    }
  };

  return (
    <div>
      <ListItem
        className={classes.listItem}
        disabled={updating}
        onClick={handleClick}
      >
        <ListItemAvatar>
          {updating ? (
            <CircularProgress />
          ) : (
            <Avatar style={{ backgroundColor: '#eaeaea' }}>
              <MessageIcon color={item.MsgRead ? 'primary' : 'secondary'} />
            </Avatar>
          )}
        </ListItemAvatar>
        <ListItemText
          primary={toPhoneNumber({ value: item.ContactPhone })}
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="body2"
                display="block"
                style={{ color: '#515151' }}
              >
                {item.Msg}
              </Typography>

              <Typography component="span" variant="caption" display="block">
                {formatDistance(new Date(item.DateCreated), new Date())} ago
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>

      <Divider />
    </div>
  );
}
