import { Grid } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { API } from 'aws-amplify';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useState } from 'react';
import { captureError } from '../../../utils/capture-error';
import { toCurrency } from '../../../utils/formatter';
import styles from './speedometer.module.css';

declare var require: NodeRequire;
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);

const useStyles = makeStyles((theme) =>
  createStyles({
    revButton: {
      backgroundColor: '#191919',
      color: 'white',
      borderRadius: '3px',
      padding: '11px',
      border: 'none',
      fontSize: '1.2em',
      cursor: 'pointer',
      marginTop: -15,
      marginBottom: '15px',
      position: 'relative',
    },
    monthlyGoalLabels: {
      color: '#555',
      fontSize: '16.5px',
      fontWeight: 700,
      fontFamily: 'Lato',
    },
    monthlyGoalInput: {
      height: '40px',
      width: '100%',
      textAlign: 'center',
      marginTop: '5px',
      fontSize: '2em',
      fontWeight: 'bold',
      fontFamily: 'inherit',
      border: '1px solid transparent',
      borderBottom: '1px solid #ddd',
      boxShadow: ' inset 0 1px 2px rgba(0,0,0,0.39)',
      [theme.breakpoints.down('md')]: {
        width: '75%',
      },
    },
  })
);

export function Speedometer() {
  const classes = useStyles();
  const [options, setOptions] = useState({});
  const [commissionValue, setCommissionValue] = useState(0);
  const [gaugeValue, setGaugeValue] = useState(0);
  const [commissionLabel, setCommissionLabel] = useState(0);
  const [seriesValue, setSeriesValue] = useState(0);
  const [speedGoal, setSpeedGoal] = useState(20);
  const [savedSpeed, setSavedSpeed] = useState(20);
  const [shaking, setShaking] = useState(false);
  const [rev, setRev] = useState(false);
  const [update, setUpdate] = useState(false);
  const audio = new Audio(
    'https://a02a41c004c75ffe8d91-b19a28bfebdacc37eb56f25bc81c2f2f.ssl.cf1.rackcdn.com/documents/arc/homepage/audio/InfinityEngineSound.mp3'
  );

  // Update Speedometer Goal
  const handleBlur = async () => {
    if (speedGoal !== null) {
      setSavedSpeed(speedGoal);
      revUp();
      const path = '/homepage/speedometer/upsert';
      const body = { Goal: speedGoal };
      try {
        await API.post('DashboardAPI', path, { body });
      } catch (error) {
        captureError({ data: { error, request: { path, body } } });
      }
    }
  };
  const revUp = () => {
    setRev(!rev);
  };

  // Rev Engine Animation
  const isMounted = React.useRef(true);
  React.useEffect(() => {
    if (isMounted.current) {
      isMounted.current = false;
    } else {
      const playPromise = audio.play();
      setSeriesValue(savedSpeed);
      setShaking(true);
      if (playPromise !== undefined) {
        playPromise.then((_) => {}).catch((error) => {});
      }
      setTimeout(() => {
        setSeriesValue(commissionValue);
        setShaking(false);
      }, 3000);
    }
    // eslint-disable-next-line
  }, [rev, update]);

  React.useEffect(() => {
    if (commissionValue < savedSpeed) {
      setGaugeValue(commissionValue);
    } else {
      setGaugeValue(savedSpeed);
    }
  }, [savedSpeed, commissionValue]);

  // Get Speedometer Data
  React.useEffect(() => {
    let promise: Promise<any> | undefined;
    const fetchData = async () => {
      const path = '/homepage/speedometer';
      try {
        promise = API.post('DashboardAPI', path, {});
        const response: {
          status: string;
          data: any;
        } = await promise;

        if (response.status === 'ok' && response.data.length > 0) {
          const data = response.data[0];
          setCommissionValue(data.Commission / 1000);
          setSavedSpeed(parseInt(data.Goal || 20));
          setCommissionLabel(data.Commission);
          setSpeedGoal(data.Goal);
        }
      } catch (error) {
        if (!API.isCancel(error)) {
          captureError({ data: { error, request: { path } } });
        }
      } finally {
        setUpdate((u) => {
          return !u;
        });
      }
    };
    fetchData();

    return () => {
      if (promise) {
        API.cancel(promise);
      }
    };
  }, []);

  React.useEffect(() => {
    setOptions({
      chart: {
        type: 'gauge',
        backgroundColor: '#FAFAFA',
        plotBackgroundImage: null,
        plotBorderWidth: 0,
        plotShadow: false,
        marginTop: 50,
        marginBottom: 20,
        spacing: 0,
        height: '375px',
        animation: {
          duration: 1000,
          easing: (t: number) =>
            t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1,
        },
      },
      title: {
        text: 'ARC Deposits',
        style: {
          color: '#000000',
          fontSize: '40px',
          fontFamily: 'Bebas Neue',
          fontWeight: 'bold',
        },
      },
      exporting: { enabled: false },
      pane: {
        startAngle: -150,
        endAngle: 150,
        background: [
          {
            backgroundColor: {
              linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
              stops: [
                [0, '#868686'],
                [0.25, '#fff'],
                [0.5, '#686868'],
                [0.75, '#fff'],
                [1, '#1a1a1a'],
              ],
            },
            borderWidth: 0,
            outerRadius: '115%',
          },
          {
            backgroundColor: {
              linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
              stops: [
                [0, '#333'],
                [1, '#FFF'],
              ],
            },
            borderWidth: 1,
            outerRadius: '107%',
          },
          {},
          {
            backgroundColor: '#DDD',
            borderWidth: 0,
            outerRadius: '105%',
            innerRadius: '103%',
          },
        ],
      },
      yAxis: {
        min: 0,
        max: savedSpeed,
        overflow: 'hidden',
        minorTickInterval: 'auto',
        minorTickWidth: 1,
        minorTickLength: 10,
        minorTickPosition: 'inside',
        minorTickColor: '#666',
        tickPixelInterval: 30,
        tickWidth: 2,
        tickPosition: 'inside',
        tickLength: 10,
        tickColor: '#333',
        labels: {
          step: 2,
          rotation: 'auto',
        },
        title: {
          y: 22,
          text: 'N247RU',
          style: {
            fontSize: '20px',
            color: '#000000',
            fontWeight: 'bolder',
            fontStyle: 'italic',
            fontFamily: 'Lato',
          },
        },
        plotBands: [
          {
            from: 0,
            to: gaugeValue,
            color: 'green',
          },
          {
            from: gaugeValue,
            to: savedSpeed,
            color: '#A2A2A2',
          },
        ],
      },
      series: [
        {
          name: 'Commissions',
          data: [seriesValue],
        },
      ],
      plotOptions: {
        gauge: {
          dataLabels: [{ enabled: false }],
          dial: {
            backgroundColor: 'orange',
            baseWidth: 10,
            baseLength: '5%',
            radius: '90%',
          },
        },
      },
      tooltip: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
    });
    // eslint-disable-next-line
  }, [seriesValue, rev]);

  return (
    <Grid item style={{ textAlign: 'center' }}>
      <div className={shaking ? styles.shaker : undefined}>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          immutable={true}
        />
      </div>
      <button className={classes.revButton} onClick={revUp}>
        REV IT
      </button>
      <div className={classes.monthlyGoalLabels}>
        <div>Last 30 Days {toCurrency({ value: commissionLabel })}</div>
        <div>Last 30 Days $ Goal in 1000s</div>
      </div>
      <div>
        <input
          placeholder="Commission Goal"
          value={speedGoal}
          onBlur={handleBlur}
          className={classes.monthlyGoalInput}
          onChange={(e) => {
            setSpeedGoal(parseInt(e.target.value) || 0);
          }}
        ></input>
      </div>
    </Grid>
  );
}
