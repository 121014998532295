import CloseIcon from '@mui/icons-material/Close';
import ForwardIcon from '@mui/icons-material/Forward';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  container: {
    height: '100dvh',
    width: '100dvw',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export function AgreementAlert(props: { onDismiss: () => void }) {
  // Hooks
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div style={{ width: '90%', maxWidth: 480 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ flex: 1, fontWeight: 'bold', fontSize: 22 }}>
                New Alliance Agreement!
              </div>

              <Tooltip title="Dismiss" placement="left" arrow>
                <IconButton
                  style={{ border: '1px solid #e1e1e1' }}
                  onClick={props.onDismiss}
                  size="large"
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div style={{ fontSize: 16 }}>
              <div style={{ padding: 2 }}>
                You have not signed the New Alliance Agreement yet.
              </div>

              <div style={{ padding: 2 }}>
                Login to{' '}
                <a
                  href="https://thealliancecontracting.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://thealliancecontracting.com/
                </a>{' '}
                to complete it now.
              </div>

              <div style={{ padding: 2 }}>Thanks,</div>

              <div style={{ padding: 2 }}>The Alliance Contracting Team</div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              component="a"
              href="https://thealliancecontracting.com/"
              target="_blank"
              rel="noopener noreferrer"
              color="secondary"
              variant="contained"
              disableElevation
              endIcon={<OpenInNewIcon />}
            >
              Go to Alliance Contracting
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              disableElevation
              onClick={props.onDismiss}
              endIcon={<ForwardIcon />}
            >
              Continue to ARC
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
