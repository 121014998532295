import Box from '@mui/material/Box';
import { Outlet } from 'react-router-dom';
import { Navigation } from './navigation';
import { Notifications } from './notifications';
import { Popups } from './pop-ups';
import { TokenError } from './token-error';

export function Dashboard() {
  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        minWidth: 0,
      }}
    >
      <Navigation />

      <Notifications />

      <TokenError />

      <Popups />

      <Outlet />
    </Box>
  );
}
