import getMonth from 'date-fns/getMonth';
import getWeekOfMonth from 'date-fns/getWeekOfMonth';
import localforage from 'localforage';
import { PopUpData } from '../../types';

/**
 * Create a unique key for a Pop Up.
 */
function createKey(params: { data: PopUpData; username: string }) {
  const { data, username } = params;

  const date = new Date();
  const monthIndex = getMonth(date);
  const weekIndex = getWeekOfMonth(date);

  if (data.Frequency === 'monthly') {
    return `${data.PopupId}-${monthIndex}-${username}`;
  } else if (data.Frequency === 'weekly') {
    return `${data.PopupId}-${monthIndex}-${weekIndex}-${username}`;
  } else {
    // Default to Frequency of "once"
    return `${data.PopupId}-${username}`;
  }
}

/**
 * Check to see if a user has seen a Pop Up.
 */
async function hasViewedPopUp(params: { data: PopUpData; username: string }) {
  const { data, username } = params;

  const popUpKey = createKey({ data, username });

  const value = await localforage.getItem(popUpKey);
  if (value) {
    return 'viewed';
  } else {
    return null;
  }
}

/**
 * Get the first unseen Pop Up from a list of Pop Ups.
 */
export async function getPopUp(params: {
  data: PopUpData[];
  username: string;
}) {
  const { data, username } = params;

  let newPopUp: PopUpData | null = null;

  // Loop over the data to find the first unviewed Pop Up
  for (const item of data) {
    // If a new unviewed Pop Up has not been found yet
    if (newPopUp === null) {
      // If the Pop Up has been viewed the value will be "viewed"
      const value = await hasViewedPopUp({ data: item, username });
      if (value === null) {
        // This Pop Up has not been viewed yet
        newPopUp = item;
      }
    }
  }

  return newPopUp;
}

/**
 * Mark a Pop Up as "viewed" so that it will not be seen again for its Frequency.
 */
export async function markPopUpViewed(params: {
  data: PopUpData;
  username: string;
}) {
  const { data, username } = params;

  const popUpKey = createKey({ data, username });

  await localforage.setItem(popUpKey, 'viewed');
}
