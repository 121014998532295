import { Grid } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import Skeleton from '@mui/material/Skeleton';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import orderBy from 'lodash/orderBy';
import React from 'react';
import { ContentCategoryName, ContentDocument } from '../../../types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    head: {
      fontSize: '40px',
      fontFamily: 'Bebas Neue',
      fontWeight: 'bold',
      color: 'black',
      marginBottom: '.5em',
      marginLeft: '0px',
    },
    imageStyle: {
      boxShadow: '2px 2px 5px gray',
      marginBottom: '26px',
      width: '100%',
      cursor: 'pointer',
    },
    section: {
      width: '100%',
      [theme.breakpoints.up('lg')]: { minHeight: '880px' },
    },
  })
);

export function Leaderboards(props: {
  content: ContentDocument[];
  loading: boolean;
}) {
  // Props
  const { content, loading } = props;
  // State
  const [newPage, setNewPage] = React.useState<number>(1);
  const [itemsPerPage] = React.useState<number>(5);
  // Hooks
  const classes = useStyles();

  const dataFiltered = content.filter(
    (c) => c.Category === ContentCategoryName['Leaderboards']
  );
  const data = orderBy(dataFiltered, 'DdocIndex');

  // Pagination
  const handlePage = (e: React.ChangeEvent<unknown>, page: number) => {
    setNewPage(page);
  };

  // Sort items
  const indexOfLastItem = newPage * itemsPerPage;
  const indexofFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexofFirstItem, indexOfLastItem);

  // Create Sorted Item Element
  const displayContests = currentItems.map(
    ({ DdocId, FileName, ThumbnailContent, ThumbnailName, Title }) => {
      if (FileName) {
        return (
          <Grid item xs={12} key={DdocId}>
            <a
              href={FileName}
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: 'block' }}
            >
              <img
                src={ThumbnailName || ThumbnailContent || undefined}
                alt={Title || ''}
                className={classes.imageStyle}
              />
            </a>
          </Grid>
        );
      } else {
        return (
          <Grid item xs={12} key={DdocId}>
            <img
              src={ThumbnailName || ThumbnailContent || undefined}
              alt={Title || undefined}
              className={classes.imageStyle}
            />
          </Grid>
        );
      }
    }
  );

  // Loading Placeholders
  let skeletons = [];
  for (var i = 0; i < 5; i++) {
    skeletons.push(
      <Skeleton
        variant="rectangular"
        key={i}
        width="100%"
        height={142}
        style={{
          marginBottom: '16px',
          boxShadow: '2px 2px 5px gray',
        }}
      />
    );
  }

  return (
    <Grid container>
      <div className={classes.head}>
        {ContentCategoryName['Leaderboards'].toUpperCase()}
      </div>

      <Grid container justifyContent="center">
        {!loading && data.length ? (
          <div className={classes.section}>{displayContests}</div>
        ) : (
          <Grid item xs={12} style={{ marginBottom: '16px' }}>
            {skeletons.map((s) => {
              return s;
            })}
          </Grid>
        )}

        <Pagination
          count={Math.ceil(data.length / 5)}
          variant="outlined"
          color="secondary"
          shape="rounded"
          onChange={handlePage}
        />
      </Grid>
    </Grid>
  );
}
