import { Grid } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import makeStyles from '@mui/styles/makeStyles';
import orderBy from 'lodash/orderBy';
import { ContentCategoryName, ContentDocument } from '../../../types';

const useStyles = makeStyles({
  head: {
    fontSize: '40px',
    fontFamily: 'Bebas Neue',
    fontWeight: 'bold',
    color: 'black',
    marginBottom: '.5em',
  },
  imageStyle: {
    boxShadow: '2px 2px 5px gray',
    width: '100%',
    cursor: 'pointer',
  },
});

export function Events(props: {
  content: ContentDocument[];
  loading: boolean;
}) {
  // Props
  const { content, loading } = props;
  // Hooks
  const classes = useStyles();

  const dataFiltered = content.filter(
    (c) => c.Category === ContentCategoryName['Upcoming Events']
  );
  const data = orderBy(dataFiltered, 'DdocIndex');

  // Create Sorted Item Element
  const displayEvents = data.map(
    ({ DdocId, FileName, ThumbnailName, ThumbnailContent, Title, URL }) => {
      if (FileName || URL) {
        return (
          <Grid item xs={12} md={6} lg={4} key={DdocId}>
            <a
              href={FileName || URL || undefined}
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: 'block' }}
            >
              <img
                src={ThumbnailName || ThumbnailContent || undefined}
                alt={Title || ''}
                className={classes.imageStyle}
              />
            </a>
          </Grid>
        );
      } else {
        return (
          <Grid item xs={12} md={6} lg={4} key={DdocId}>
            <img
              src={ThumbnailName || ThumbnailContent || undefined}
              alt={Title || undefined}
              className={classes.imageStyle}
            />
          </Grid>
        );
      }
    }
  );

  return (
    <Grid container>
      <div className={classes.head}>
        {ContentCategoryName['Upcoming Events'].toUpperCase()}
      </div>

      {!loading && data.length ? (
        <Grid container spacing={2}>
          {displayEvents}
        </Grid>
      ) : (
        <Grid container spacing={2}>
          {Array.from({ length: 3 }).map((_, index) => {
            return (
              <Grid key={index} item xs={12} md={6} lg={4}>
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height={130}
                  style={{ boxShadow: '2px 2px 5px gray' }}
                />
              </Grid>
            );
          })}
        </Grid>
      )}
    </Grid>
  );
}
