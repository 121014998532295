import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { AnimateIn, AnimateInProps } from '../animate-in';
import { BackToTop } from '../back-to-top';

const useStyles = makeStyles({
  content: {
    width: '94%',
    margin: '0 auto',
    paddingTop: 10,
  },
  wrapper: {
    flex: 1,
    overflowX: 'auto',
  },
});

export function RouteWrapper(props: {
  children: React.ReactNode;
  paddingBottom?: number;
  nofade?: AnimateInProps['nofade'];
  notransform?: AnimateInProps['notransform'];
}) {
  // State
  const [showButtonTop, setShowButtonTop] = React.useState(false);
  // Hooks
  const wrapperEl = React.useRef<HTMLDivElement>(null);
  const classes = useStyles();

  React.useEffect(() => {
    const wrapperDiv = wrapperEl.current;

    wrapperDiv?.addEventListener('scroll', (event) => {
      const target = event.target as HTMLTextAreaElement;
      if (target.scrollTop > 500) {
        setShowButtonTop(true);
      } else {
        setShowButtonTop(false);
      }
    });

    return () => wrapperDiv?.removeEventListener('scroll', () => {});
  }, [wrapperEl]);

  const handleScrollToTop = () => {
    if (wrapperEl.current !== null) {
      wrapperEl.current.scrollTo(0, 0);
    }
  };

  const paddingBottom =
    props.paddingBottom !== undefined ? props.paddingBottom : 100;

  return (
    <React.Fragment>
      <AnimateIn
        to="top"
        ref={wrapperEl}
        className={classes.wrapper}
        nofade={props.nofade}
        notransform={props.notransform}
      >
        <div className={classes.content} style={{ paddingBottom }}>
          {props.children}
        </div>
      </AnimateIn>

      {showButtonTop && <BackToTop onClick={handleScrollToTop} />}
    </React.Fragment>
  );
}
