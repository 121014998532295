import React, { Dispatch } from 'react';
import { AgentSelect, NotificationAction, NotificationText } from '../types';

// State
type AppState = {
  drawerOpen: boolean;
  notificationsOpen: boolean;
  notificationsData: {
    texts: NotificationText[];
    actions: NotificationAction[];
  };
  impersonatedAgent: AgentSelect | null;
};
const initialState: AppState = {
  drawerOpen: false,
  notificationsOpen: false,
  notificationsData: {
    texts: [],
    actions: [],
  },
  impersonatedAgent: null,
};

// Actions
type AppActions =
  | { type: 'TOGGLE_DRAWER' }
  | { type: 'DRAWER_CLOSE' }
  | { type: 'TOGGLE_NOTIFICATIONS_DRAWER' }
  | {
      type: 'SET_NOTIFICATIONS_DATA';
      payload: {
        texts: NotificationText[];
        actions: NotificationAction[];
      };
    }
  | { type: 'IMPERSONATE_AGENT'; payload: AgentSelect | null };

const reducer = (state: AppState, action: AppActions) => {
  switch (action.type) {
    case 'TOGGLE_DRAWER': {
      return { ...state, drawerOpen: !state.drawerOpen };
    }
    case 'DRAWER_CLOSE': {
      return { ...state, drawerOpen: false };
    }
    case 'TOGGLE_NOTIFICATIONS_DRAWER': {
      return { ...state, notificationsOpen: !state.notificationsOpen };
    }
    case 'SET_NOTIFICATIONS_DATA': {
      return { ...state, notificationsData: action.payload };
    }
    case 'IMPERSONATE_AGENT': {
      return { ...state, impersonatedAgent: action.payload };
    }
    default: {
      return { ...state };
    }
  }
};

export const AppContext = React.createContext(
  {} as {
    state: AppState;
    dispatch: Dispatch<AppActions>;
  }
);

export function AppProvider(props: { children: React.ReactNode }) {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {props.children}
    </AppContext.Provider>
  );
}
