import AccountBoxIcon from '@mui/icons-material/AccountBox';
import DescriptionIcon from '@mui/icons-material/Description';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import NoteIcon from '@mui/icons-material/Note';
import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { API } from 'aws-amplify';
import formatDistance from 'date-fns/formatDistance';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../contexts/app-context';
import { NotificationAction } from '../../types';
import { captureError } from '../../utils/capture-error';

const useStyles = makeStyles({
  listItem: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f1f1f1',
    },
  },
});

export function ActionListItem(props: { item: NotificationAction }) {
  // Context
  const { dispatch } = React.useContext(AppContext);
  // Props
  const { item } = props;
  // State
  const [updating, setUpdating] = React.useState(false);
  // Hooks
  const classes = useStyles();
  const navigate = useNavigate();

  let icon = <div />;
  let primary = '';
  let description = '';
  if (item.NotificationType === 'Note') {
    icon = <NoteIcon color={item.Read === '1' ? 'primary' : 'secondary'} />;
    primary = 'New Note!';
  } else if (item.NotificationType === 'Appointment') {
    icon = (
      <EventAvailableIcon color={item.Read === '1' ? 'primary' : 'secondary'} />
    );
    primary = 'New Appointment!';
  } else if (item.NotificationType === 'Document') {
    icon = (
      <DescriptionIcon color={item.Read === '1' ? 'primary' : 'secondary'} />
    );
    primary = 'New Document!';
    description = item.FileName || '';
  } else if (
    item.NotificationType === 'Greensheet' ||
    item.NotificationType === 'GreensheetForm'
  ) {
    icon = (
      <AccountBoxIcon color={item.Read === '1' ? 'primary' : 'secondary'} />
    );
    primary = 'New Greensheet!';
    description = item.FileName || '';
  }

  const handleClick = async () => {
    const body = { NId: item.NId };
    const path = '/crm/contacts/notification/actions/markRead';
    try {
      setUpdating(true);
      // Mark the action notification as read
      await API.post('DashboardAPI', path, { body });
      // Make sure there is no impersonated agent for when the CRM loads
      dispatch({ type: 'IMPERSONATE_AGENT', payload: null });
      // Route to the CRM with ContactId in the url
      navigate(`/business/crm?ContactId=${item.ContactId}`);
      // Close the drawer
      dispatch({ type: 'TOGGLE_NOTIFICATIONS_DRAWER' });
    } catch (error) {
      setUpdating(false);
      captureError({ data: { error, request: { body, path } } });
    }
  };

  return (
    <div>
      <ListItem
        className={classes.listItem}
        disabled={updating}
        onClick={handleClick}
      >
        <ListItemAvatar>
          {updating ? (
            <CircularProgress />
          ) : (
            <Avatar style={{ backgroundColor: '#eaeaea' }}>{icon}</Avatar>
          )}
        </ListItemAvatar>
        <ListItemText
          primary={primary}
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="body2"
                display="block"
                style={{ color: '#515151' }}
              >
                {description}
              </Typography>

              <Typography component="span" variant="caption" display="block">
                {formatDistance(new Date(item.DateAdded), new Date())} ago
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>

      <Divider />
    </div>
  );
}
