import React from 'react';
import { AuthContext } from '../contexts/auth-context';
import { ARC_GROUP, BillingPermissionData, BillingUserAccess } from '../types';

export function usePermissions() {
  // Context
  const { state } = React.useContext(AuthContext);

  // Authenticated User Permissions to be returned from hook
  const permissions: {
    groups: string[];
    hasGroup: (group: string) => boolean;
    checkPermissions: (list: string[]) => boolean;
    getBillingAccess: (name: BillingPermissionData['Name']) => number;
    checkBillingAccess: (
      name: BillingPermissionData['Name'],
      access: BillingUserAccess[],
    ) => boolean;
    checkARAccess: (
      program: BillingPermissionData['Program'],
      access: BillingUserAccess[],
    ) => boolean;
  } = {
    groups: [],
    hasGroup: (group) => false,
    checkPermissions: (list) => false,
    getBillingAccess: (name) => 0,
    checkBillingAccess: (name, access) => false,
    checkARAccess: (program, access) => false,
  };

  if (state.session) {
    const payload = state.session.getIdToken().decodePayload();
    if (payload['cognito:groups']) {
      const cognitoGroups = payload['cognito:groups'] as string[];

      permissions.groups = cognitoGroups;

      permissions.hasGroup = (group) => {
        return cognitoGroups.includes(group);
      };

      permissions.checkPermissions = (list) => {
        if (cognitoGroups.includes(ARC_GROUP.ARC_GlobalAdmin)) {
          return true;
        } else {
          const values: boolean[] = list.map((i) => {
            return cognitoGroups.includes(i);
          });
          return values.includes(true);
        }
      };
    }
  }

  if (state.billingPermissions.length) {
    permissions.getBillingAccess = (name) => {
      let access = 0;
      const permission = state.billingPermissions.find((i) => i.Name === name);
      if (permission && permission.AvId) {
        access = permission.AvId;
      }
      return access;
    };

    permissions.checkBillingAccess = (name, access) => {
      const permission = state.billingPermissions.find((i) => i.Name === name);
      if (permission && permission.AvId) {
        return access.includes(permission.AvId);
      } else {
        return false;
      }
    };

    permissions.checkARAccess = (program, access) => {
      const permission = state.billingPermissions.find(
        (i) => i.Program === program,
      );
      if (permission && permission.AvId) {
        return access.includes(permission.AvId);
      } else {
        return false;
      }
    };
  }

  return permissions;
}
