import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ButtonBase from '@mui/material/ButtonBase';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { AppContext } from '../../contexts/app-context';
import { usePermissions } from '../../hooks/use-permissions';
import { NavDataSection } from './nav-data';
import { NavSectionLink } from './nav-section-link';

const useStyles = makeStyles({
  navButton: {
    color: '#fff',
    padding: 4,
    borderRadius: 2,
    marginLeft: 10,
    marginRight: 10,
  },
  menuPaper: {
    top: '48px !important',
  },
});

export function NavSection(props: {
  item: NavDataSection;
  isMobile?: boolean;
}) {
  // Props
  const { item } = props;
  // Context
  const { state } = React.useContext(AppContext);
  // State
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // Hooks
  const classes = useStyles();
  const { checkPermissions, checkBillingAccess, hasGroup } = usePermissions();

  React.useEffect(() => {
    if (!state.drawerOpen) {
      setAnchorEl(null);
    }
  }, [state]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let canView = true;
  if (item.hasGroup) {
    // User must have this specific group to view link
    canView = hasGroup(item.hasGroup);
  } else if (item.permissions) {
    // User must have one of the permissions
    canView = checkPermissions(item.permissions);
  }

  let hideFromBilling = false;
  if (item.name && item.access) {
    // Only Billing Navigation items with name and access will be hidden and checked
    hideFromBilling = !checkBillingAccess(item.name, item.access);
  }

  const menuId = item.title.trim().replace(/\s/g, '').toLowerCase() + '-menu';

  if (!hideFromBilling && props.isMobile && canView) {
    return (
      <List
        dense
        component="nav"
        aria-labelledby={menuId}
        subheader={
          <ListSubheader
            style={{ backgroundColor: '#fefefe' }}
            component="div"
            id={menuId}
          >
            {item.title}
          </ListSubheader>
        }
      >
        {item.links.map((link) => (
          <NavSectionLink key={link.title} item={link} />
        ))}
      </List>
    );
  } else if (!hideFromBilling && canView) {
    return (
      <div>
        <ButtonBase
          focusRipple
          className={classes.navButton}
          onClick={handleClick}
          aria-controls={menuId}
          aria-haspopup="true"
        >
          <Typography variant="body1">{item.title}</Typography>
          <ArrowDropDownIcon />
        </ButtonBase>

        <Menu
          classes={{ paper: classes.menuPaper }}
          id={menuId}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {item.links.map((link) => (
            <NavSectionLink key={link.title} item={link} />
          ))}
        </Menu>
      </div>
    );
  } else {
    return null;
  }
}
