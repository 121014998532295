import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../contexts/auth-context';
import { usePermissions } from '../../hooks/use-permissions';
import { ARC_GROUP } from '../../types';
import { NavDataLink } from './nav-data';

const useStyles = makeStyles({
  listItemText: {
    marginLeft: 10,
    marginRight: 10,
  },
});

// Material-UI needs to be able to Forward the Ref to the List Items when they are located in another file
// https://material-ui.com/guides/composition/#caveat-with-refs
export const NavSectionLink = React.forwardRef(
  (props: { item: NavDataLink }, ref: React.Ref<any> | undefined) => {
    // Props
    const { item } = props;
    // Context
    const { state } = React.useContext(AuthContext);
    // State
    const [show, setShow] = React.useState<boolean>(false);
    // Hooks
    const classes = useStyles();
    const { checkPermissions, checkBillingAccess, hasGroup } = usePermissions();

    // Without stopping thr propagation of the 'tab'
    // the menu will close instead of focusing on the next link
    const stopPropagationForTab = (
      event: React.KeyboardEvent<HTMLAnchorElement | HTMLDivElement>
    ) => {
      if (event.key === 'Tab') {
        event.stopPropagation();
      }
    };

    let canView = true;
    if (item.hasGroup && item.hasGroup === ARC_GROUP.ARC_BillingSystem) {
      // Check that the Billing User has the necessary group and some permissions have been set
      const hasPermissions: boolean = Boolean(state.billingPermissions.length);
      canView = hasGroup(item.hasGroup) && hasPermissions;
    } else if (item.hasGroup) {
      // User must have this specific group to view link
      canView = hasGroup(item.hasGroup);
    } else if (item.permissions) {
      // User must have one of the permissions
      canView = checkPermissions(item.permissions);
    }

    let hideFromBilling = false;
    if (item.name && item.access) {
      // Only Billing Navigation items with name and access will be hidden and checked
      hideFromBilling = !checkBillingAccess(item.name, item.access);
    }

    if (!hideFromBilling && item.children && canView) {
      return (
        <React.Fragment>
          <ListItem
            ref={ref}
            dense
            button
            onClick={() => setShow((currentState) => !currentState)}
            onKeyDown={stopPropagationForTab}
          >
            {item.startIcon || null}
            <ListItemText
              primary={item.title}
              className={classes.listItemText}
            />
            {show ? <ExpandLess /> : <ExpandMore />}
          </ListItem>

          <Collapse in={show} timeout="auto" unmountOnExit>
            <List dense component="div" disablePadding>
              {item.children.map((link) => (
                <NavSectionLink key={link.title} item={link} />
              ))}
            </List>
          </Collapse>
        </React.Fragment>
      );
    } else if (!hideFromBilling && item.to && canView) {
      return (
        <ListItem
          ref={ref}
          dense
          button
          component={Link}
          to={item.to}
          onKeyDown={(event: React.KeyboardEvent<HTMLAnchorElement>) => {
            if (event.key === 'Tab') {
              event.stopPropagation();
            }
          }}
        >
          {item.startIcon || null}
          <ListItemText primary={item.title} className={classes.listItemText} />
        </ListItem>
      );
    } else if (!hideFromBilling && item.href && canView) {
      return (
        <ListItem
          ref={ref}
          dense
          button
          component="a"
          href={item.href}
          target="_blank"
          rel="noreferrer"
          onKeyDown={stopPropagationForTab}
        >
          {item.startIcon || null}
          <ListItemText primary={item.title} className={classes.listItemText} />
        </ListItem>
      );
    } else {
      return null;
    }
  }
);
