import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import YouTubeIcon from '@mui/icons-material/YouTube';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment-timezone';
import React from 'react';
import logoCallActivity from '../../../assets/logo-call-activity.png';
import logoCallWednesday from '../../../assets/logo-call-wednesday.png';
import logoProdCast from '../../../assets/logo-prodcast.png';

// useInterval from
// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
type IntervalFunction = () => unknown | void;
function useInterval(callback: IntervalFunction, delay: number) {
  const savedCallback = React.useRef<IntervalFunction | null>(null);

  // Remember the latest callback.
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  React.useEffect(() => {
    function tick() {
      if (savedCallback.current !== null) {
        savedCallback.current();
      }
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

interface ConferenceCall {
  day: number;
  name: string;
  dateDay: string;
  dateTime: string;
  phone: string;
  code: string;
  youtube: string;
  logo: string;
}

const conferenceCalls: ConferenceCall[] = [
  {
    day: 3,
    name: 'TWC',
    dateDay: 'Wednesday',
    dateTime: '@ 12:30 PM ET',
    phone: '312-626-6799',
    code: '969 606 853#',
    youtube: 'https://www.youtube.com/theallianceyt',
    logo: logoCallWednesday,
  },
  {
    day: 4,
    name: 'Product Call',
    dateDay: 'Thursday',
    dateTime: '@ 1:00 PM ET',
    phone: '253-215-8782',
    code: '875 5255 6016',
    youtube:
      'https://us02web.zoom.us/j/85370582123?pwd=UzFydkcyVmhoWjNlSmxxREhaR3U4QT09',
    logo: logoProdCast,
  },
  {
    day: 5,
    name: 'Activity Call',
    dateDay: 'Friday',
    dateTime: '@ 10:00 AM ET',
    phone: '312-626-6799',
    code: '969 606 853#',
    youtube: 'https://www.youtube.com/theallianceyt',
    logo: logoCallActivity,
  },
];

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      fontSize: 18,
      fontFamily: 'Bebas Neue',
      fontWeight: 'bold',
      lineHeight: 1,
      [theme.breakpoints.up('sm')]: {
        fontSize: 22,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 26,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 30,
      },
    },
    iconText: {
      whiteSpace: 'nowrap',
      fontSize: 12,
      [theme.breakpoints.up('sm')]: {
        fontSize: 14,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 16,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 18,
      },
    },
  }),
);

function DisplayCall(props: { call: ConferenceCall }) {
  // Props
  const { call } = props;
  // State
  const [isLive, setIsLive] = React.useState(false);
  // Hooks
  const classes = useStyles();

  const getStatus = () => {
    const day = new Date().getDay();

    if (call.day === day) {
      let hour = moment().tz('America/New_York').format('H');
      if (hour.toString().length < 2) {
        hour = `0${hour}`;
      }

      let minute = moment().tz('America/New_York').format('m');
      if (minute.toString().length < 2) {
        minute = `0${minute}`;
      }

      const timeOfDay = `${hour}:${minute}`;
      if (day === 3 && timeOfDay >= '12:15' && timeOfDay <= '14:00') {
        return true;
      } else if (day === 4 && timeOfDay >= '12:45' && timeOfDay <= '14:00') {
        return true;
      } else if (day === 5 && timeOfDay >= '09:45' && timeOfDay <= '11:00') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  React.useEffect(() => {
    setIsLive(getStatus());
    // ignore 'getStatus' missing dependency array warning
    // eslint-disable-next-line
  }, []);

  // 10 second delay between intervals
  const delay = 10 * 1000;
  useInterval(() => {
    setIsLive(getStatus());
  }, delay);

  return (
    <Grid item xs={12}>
      <Paper
        style={{
          position: 'relative',
          backgroundColor: '#eaeaea',
          borderWidth: 4,
          borderStyle: 'solid',
          borderColor: isLive ? 'red' : 'transparent',
        }}
      >
        <div
          style={{
            height: 128,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              padding: 8,
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              paddingLeft: 16,
              paddingRight: 16,
            }}
          >
            <img
              src={call.logo}
              alt={call.name}
              style={{ width: '100%', maxWidth: 116 }}
            />
          </div>
          <div style={{ flex: 1.6, paddingLeft: 8 }}>
            <div className={classes.title}>Every {call.dateDay}</div>
            <div className={classes.title}>{call.dateTime}</div>
          </div>

          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <IconButton
              aria-label={`Watch ${call.name}`}
              href={call.youtube}
              target="_blank"
              rel="noopener noreferrer"
              size="large"
            >
              <YouTubeIcon fontSize="large" />
            </IconButton>
            <div className={classes.iconText}>Watch Live</div>
          </div>

          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <IconButton
              aria-label={`Listen to ${call.name}`}
              href={`tel:${call.phone},,,${call.code}`}
              size="large"
            >
              <HeadsetMicIcon fontSize="large" />
            </IconButton>
            <div className={classes.iconText}>Dial In</div>
          </div>
        </div>

        {isLive ? (
          <div style={{ position: 'absolute', top: -15, right: 20 }}>
            <div
              style={{
                backgroundColor: '#eaeaea',
                padding: 4,
                paddingLeft: 8,
                paddingRight: 8,
                color: 'red',
                border: '4px solid red',
                borderRadius: 4,
                fontSize: 18,
                fontFamily: 'Bebas Neue',
                fontWeight: 'bold',
                lineHeight: 1.1,
                letterSpacing: 1.1,
              }}
            >
              Live Now
            </div>
          </div>
        ) : null}
      </Paper>
    </Grid>
  );
}

export function WeeklyCalls() {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div
            style={{
              fontSize: 40,
              fontFamily: 'Bebas Neue',
              fontWeight: 'bold',
            }}
          >
            WEEKLY CALLS
          </div>
        </Grid>

        {conferenceCalls.map((call) => {
          return <DisplayCall key={call.name} call={call} />;
        })}
      </Grid>
    </div>
  );
}
