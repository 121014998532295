import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import AppBar from '@mui/material/AppBar';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import { useWindowWidth } from '@react-hook/window-size';
import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as ARCLogo } from '../../assets/arc-logo.svg';
import { AppContext } from '../../contexts/app-context';
import { AuthContext } from '../../contexts/auth-context';
import { NavDataSection } from './nav-data';
import { NavSection } from './nav-section';

const useStyles = makeStyles({
  navSections: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export function NavBar(props: { data: NavDataSection[] }) {
  // Context
  const {
    dispatch,
    state: { notificationsData },
  } = React.useContext(AppContext);
  const { state } = React.useContext(AuthContext);
  // Hooks
  const width = useWindowWidth();
  const classes = useStyles();

  const isDesktop = width > 1024;

  // Unread Texts
  const unreadTexts = notificationsData.texts.filter((i) => !i.MsgRead);
  // Unread Actions
  const unreadActions = notificationsData.actions.filter((i) => i.Read === '0');
  // Unread Notifications
  const unreadNotifications = unreadActions.length + unreadTexts.length;

  const notificationsButton = (
    <IconButton
      aria-label="notifications"
      style={{ marginLeft: 10 }}
      onClick={() => dispatch({ type: 'TOGGLE_NOTIFICATIONS_DRAWER' })}
      size="large"
    >
      <Badge badgeContent={unreadNotifications} color="secondary">
        {unreadNotifications > 0 ? (
          <NotificationsIcon style={{ color: '#fff' }} />
        ) : (
          <NotificationsNoneIcon style={{ color: '#fff' }} />
        )}
      </Badge>
    </IconButton>
  );

  return (
    <AppBar
      position="relative"
      style={{ backgroundColor: grey[900] }}
      elevation={0}
    >
      <Toolbar>
        <Link to="/home" aria-label="ARC Home page">
          <ARCLogo />
        </Link>

        {isDesktop ? (
          <div className={classes.navSections}>
            {props.data.map((item) => {
              return <NavSection key={item.title} item={item} />;
            })}
          </div>
        ) : (
          <div style={{ flex: 1 }} />
        )}

        {isDesktop ? (
          <Button
            component={Link}
            to="/account/info"
            size="large"
            style={{ color: '#fff' }}
            startIcon={<AccountCircleIcon />}
          >
            {state.user.username}
          </Button>
        ) : (
          <IconButton
            edge="start"
            color="primary"
            aria-label="menu"
            style={{ color: '#fff' }}
            onClick={() => dispatch({ type: 'TOGGLE_DRAWER' })}
            size="large"
          >
            <MenuIcon />
          </IconButton>
        )}

        {notificationsButton}
      </Toolbar>
    </AppBar>
  );
}
