import LinearProgress from '@mui/material/LinearProgress';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Dashboard } from '../components/dashboard';
import { MaintenanceMessageAdmins } from '../components/maintenance-message';
import { RequireAuth } from '../components/require-auth';
import { usePermissions } from '../hooks/use-permissions';
import { ARC_GROUP } from '../types';
import { captureError } from '../utils/capture-error';
import { ActivityState } from './home/activity-state';
import { Home } from './home/index';
import { SignIn } from './sign-in';

const AppRoutes = {
  Account: React.lazy(() => import('./account/account-routes')),
  Admin: React.lazy(() => import('./admin/admin-routes')),
  Business: React.lazy(() => import('./business/business-routes')),
  Carriers: React.lazy(() => import('./carriers/carriers-routes')),
  Connections: React.lazy(() => import('./connections/connections-routes')),
  Resources: React.lazy(() => import('./resources/resources-routes')),
  Team: React.lazy(() => import('./team/team-routes')),
};

export function Root() {
  // Hooks
  const { checkPermissions } = usePermissions();

  // Query - Check for Maintenance
  const pathMaint = process.env.REACT_APP_MAINT_URL;
  const queryMaint = useQuery({
    enabled: pathMaint !== undefined,
    queryKey: [pathMaint],
    queryFn: async () => {
      if (pathMaint) {
        const body = { data: { origin: window.origin } };
        const response = await fetch(pathMaint, {
          body: JSON.stringify(body),
          headers: { 'Content-Type': 'application/json' },
          method: 'POST',
        });
        const data: {
          data: {
            maintenanceActive: boolean;
            maintenanceBegin: string;
            maintenanceEnd: string;
          };
        } = await response.json();

        return data.data;
      }

      return null;
    },
    onError: (error) => captureError({ data: { error } }),
  });

  const isAdmin = checkPermissions([
    ARC_GROUP.ARC_ABTF,
    ARC_GROUP.ARC_Accounting,
    ARC_GROUP.ARC_Commissions,
    ARC_GROUP.ARC_LPT,
    ARC_GROUP.ARC_Marketing,
    ARC_GROUP.ARC_PresidentsClub,
    ARC_GROUP.ARC_BillingSystem,
    ARC_GROUP.LDS_KeyingMgr,
  ]);

  // Agents cannot access the ARC during Maintenance
  // Admins can access the ARC during Maintentnace
  const displayAdminMaint =
    isAdmin &&
    queryMaint.data &&
    queryMaint.data.maintenanceActive &&
    queryMaint.data.maintenanceEnd;

  return (
    <React.Fragment>
      {displayAdminMaint && queryMaint.data ? (
        <MaintenanceMessageAdmins
          maintenanceEnd={queryMaint.data.maintenanceEnd}
        />
      ) : null}

      <Routes>
        <Route path="sign-in" element={<SignIn />} />

        <Route
          path="/"
          element={
            <RequireAuth isAdmin={isAdmin} maintData={queryMaint.data}>
              <Dashboard />
            </RequireAuth>
          }
        >
          <Route
            path="home"
            element={
              <ActivityState>
                <Home />
              </ActivityState>
            }
          />

          <Route
            path="account/*"
            element={
              <React.Suspense fallback={<LinearProgress />}>
                <AppRoutes.Account />
              </React.Suspense>
            }
          />
          <Route
            path="business/*"
            element={
              <React.Suspense fallback={<LinearProgress />}>
                <AppRoutes.Business />
              </React.Suspense>
            }
          />
          <Route
            path="team/*"
            element={
              <React.Suspense fallback={<LinearProgress />}>
                <AppRoutes.Team />
              </React.Suspense>
            }
          />
          <Route
            path="carriers/*"
            element={
              <React.Suspense fallback={<LinearProgress />}>
                <AppRoutes.Carriers />
              </React.Suspense>
            }
          />
          <Route
            path="resources/*"
            element={
              <React.Suspense fallback={<LinearProgress />}>
                <AppRoutes.Resources />
              </React.Suspense>
            }
          />
          <Route
            path="connections/*"
            element={
              <React.Suspense fallback={<LinearProgress />}>
                <AppRoutes.Connections />
              </React.Suspense>
            }
          />
          <Route
            path="admin/*"
            element={
              isAdmin ? (
                <React.Suspense fallback={<LinearProgress />}>
                  <AppRoutes.Admin />
                </React.Suspense>
              ) : (
                <Navigate to="home" />
              )
            }
          />

          <Route path="/" element={<Navigate to="home" />} />
          <Route path="*" element={<Navigate to="home" />} />
        </Route>
      </Routes>
    </React.Fragment>
  );
}
