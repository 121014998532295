import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { API } from 'aws-amplify';
import orderBy from 'lodash/orderBy';
import React from 'react';
import { AppContext } from '../../contexts/app-context';
import { captureError } from '../../utils/capture-error';
import { TextListItem } from './text-list-item';

const useStyles = makeStyles({
  list: {
    flex: 1,
    minHeight: 0,
    display: 'flex',
    flexDirection: 'column',
  },
});

export function TextList() {
  // Contexts
  const {
    state: { notificationsData },
    dispatch,
  } = React.useContext(AppContext);
  // State
  const [updating, setUpdating] = React.useState(false);
  // Hooks
  const classes = useStyles();

  // Order notifications by DateCreated
  const data = orderBy(
    notificationsData.texts,
    ['MsgRead', 'DateCreated'],
    ['asc', 'desc']
  );

  // Unread texts
  const unreadTexts = data.filter((i) => !i.MsgRead);

  const handleReadAll = async () => {
    const body = {};
    const path = '/crm/contacts/notification/texts/markAllRead';
    try {
      setUpdating(true);
      // Marking all text notifications as read
      await API.post('DashboardAPI', path, { body });
      // Close the drawer
      dispatch({ type: 'TOGGLE_NOTIFICATIONS_DRAWER' });
    } catch (error) {
      setUpdating(false);
      captureError({ data: { error, request: { body, path } } });
    }
  };

  return (
    <div className={classes.list}>
      {unreadTexts.length ? (
        <React.Fragment>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              size="small"
              color="secondary"
              disabled={updating}
              onClick={handleReadAll}
            >
              {updating ? 'Marking as read...' : 'Mark all as read'}
            </Button>
          </div>

          <Divider />
        </React.Fragment>
      ) : null}

      {data.length ? (
        <div style={{ flex: 1, overflowY: 'scroll' }}>
          {data.map((item) => {
            return <TextListItem key={item.CMId} item={item} />;
          })}
        </div>
      ) : (
        <Typography align="center" style={{ color: '#9b9b9b', paddingTop: 20 }}>
          No new text messages!
        </Typography>
      )}
    </div>
  );
}
