import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { AnimateIn } from './animate-in';

const useStyles = makeStyles({
  appBar: {
    backgroundColor: '#fff',
    borderBottom: '1px solid #efefefcc',
  },
});

export function AppHeader(props: {
  routeTitle: string;
  headerContent?: React.ReactNode;
  wrapContentForMobile?: boolean;
}) {
  // Hooks
  const classes = useStyles();

  return (
    <AppBar
      elevation={0}
      position="relative"
      component="div"
      className={classes.appBar}
    >
      <Toolbar
        sx={{
          width: '96%',
          margin: '0 auto',
          flexDirection: props.wrapContentForMobile
            ? { xs: 'column', sm: 'row' }
            : 'row',
        }}
      >
        <AnimateIn nofade="true">
          <Typography color="primary" component="h1" variant="h6">
            {props.routeTitle}
          </Typography>
        </AnimateIn>

        <div style={{ flex: 1 }} />

        {props.headerContent}
      </Toolbar>
    </AppBar>
  );
}
