import Close from '@mui/icons-material/Close';
import Edit from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import localforage from 'localforage';
import React from 'react';
import { OptionDetails } from '.';

export function SelectMeter(props: {
  options: OptionDetails[];
  username: string;
  update: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  // Props
  const { options, username, update } = props;

  // State
  const [open, setOpen] = React.useState(false);
  const [selection, setSelection] = React.useState<string | undefined>(
    'Kiwi Birds'
  );

  // Check and highlight current selection
  React.useEffect(() => {
    async function getMeter() {
      const value = await localforage.getItem(`${username}-contest-meter`);
      if (value !== null) {
        setSelection(options.find((opt) => opt.name === value)?.name);
      } else {
        setSelection('Kiwi Birds');
      }
    }
    getMeter();
  }, [username, options, open]);

  // Update local storage with selection
  const handleSave = async () => {
    try {
      await localforage.setItem(`${username}-contest-meter`, selection);
    } finally {
      setOpen(false);
      update((currentState) => !currentState);
    }
  };

  return (
    <React.Fragment>
      <IconButton
        size="small"
        style={{
          position: 'absolute',
          zIndex: 2,
          top: 5,
          right: 5,
          backgroundColor: 'black',
        }}
        onClick={() => setOpen(true)}
      >
        <Edit fontSize="small" style={{ color: 'white' }} />
      </IconButton>
      <Dialog
        open={open}
        maxWidth="md"
        fullWidth
        onClose={() => setOpen(false)}
      >
        <DialogTitle
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h6">Select Contest Meter</Typography>
          <div style={{ flex: 1 }} />
          <IconButton size="small" onClick={() => setOpen(false)}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers style={{ height: 455 }}>
          <Grid container spacing={2}>
            {options.map((opt: OptionDetails) => {
              return (
                <Grid
                  key={opt.name}
                  xs={12}
                  sm={4}
                  item
                  style={{
                    position: 'relative',
                    textAlign: 'center',
                  }}
                >
                  <Typography
                    style={{
                      fontSize: 18,
                      fontFamily: 'Lato',
                      fontWeight: 'bold',
                    }}
                  >
                    {opt.name}
                  </Typography>
                  <div style={{ position: 'relative' }}>
                    <img
                      src={opt.full}
                      alt={opt.name}
                      style={{
                        height: 360,
                        borderRadius: 5,
                        boxShadow: '3px 3px 5px',
                        cursor: 'pointer',
                        border:
                          selection === opt.name
                            ? `3px solid #2979FF`
                            : undefined,
                      }}
                      onClick={() => setSelection(opt.name)}
                    />
                  </div>
                  <Radio
                    checked={selection === opt.name}
                    onChange={() => setSelection(opt.name)}
                    value={opt.name}
                    name="radio-button"
                    inputProps={{ 'aria-label': opt.name }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            style={{ margin: 5 }}
            onClick={handleSave}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
