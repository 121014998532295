import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Auth } from 'aws-amplify';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as AllianceLogo } from '../../assets/alliance-logo.svg';
import { AuthContext } from '../../contexts/auth-context';

const useStyles = makeStyles({
  container: {
    height: '100vh',
    width: '100vw',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  greeting: {
    textAlign: 'center',
    marginTop: 10,
    fontWeight: 'lighter',
  },
  logoContainer: {
    backgroundColor: '#212121',
    padding: 16,
    minWidth: 300,
    minHeight: 160,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export function SignIn() {
  // Context
  const { state } = React.useContext(AuthContext);
  // Hooks
  const classes = useStyles();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (state.user !== undefined) {
      navigate('/home');
    }
  }, [state.user, navigate]);

  // Forgot Password Link
  const linkForForgot = `https://${process.env.REACT_APP_AWS_DOMAIN}/forgotPassword?client_id=${process.env.REACT_APP_AWS_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_AWS_REDIRECT_SIGN_IN}`;
  // Register new recruit link
  const linkForRegister =
    'https://thealliancecontracting.com/sign-up/the-alliance';

  return (
    <div className={classes.container}>
      <Paper>
        <Paper elevation={0} className={classes.logoContainer}>
          <AllianceLogo height={102} width={256} />
        </Paper>

        <Typography className={classes.greeting} variant="h6" gutterBottom>
          Access your dashboard
        </Typography>

        <div style={{ padding: 10 }}>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            fullWidth
            onClick={() => Auth.federatedSignIn()}
          >
            Sign in
          </Button>
        </div>

        <div style={{ padding: 10 }}>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            fullWidth
            href={linkForForgot}
          >
            Forgot password
          </Button>
        </div>
      </Paper>

      <Paper style={{ marginTop: 10, padding: 10, minWidth: 300 }}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          href={linkForRegister}
        >
          Fastract a new recruit
        </Button>
      </Paper>
    </div>
  );
}
