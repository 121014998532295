import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { animated, useSpring } from 'react-spring';

const useStyles = makeStyles({
  toTopButton: {
    backgroundColor: '#fff',
    cursor: 'pointer',
    height: 60,
    width: 60,
    borderRadius: 50,
    border: 'none',
    boxShadow: '0 5px 4px 0 rgba(0, 0, 0, .26)',
    position: 'fixed',
    left: 20,
    bottom: 20,
    zIndex: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:focus': {
      outline: 'none',
    },
  },
});

export function BackToTop(props: {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) {
  // Hooks
  const classes = useStyles();
  const values = useSpring({
    opacity: 1,
    transform: 'translate3d(0, 0, 0)',
    from: { opacity: 0, transform: 'translate3d(0, 10px, 0)' },
  });

  return (
    <animated.button
      aria-label="back to top"
      className={classes.toTopButton}
      style={values}
      onClick={props.onClick}
    >
      <ArrowUpwardIcon color="secondary" fontSize="large" />
    </animated.button>
  );
}
