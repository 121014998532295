import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { API } from 'aws-amplify';
import orderBy from 'lodash/orderBy';
import React from 'react';
import { CarrierHeaderLink } from '../../types';
import { captureError } from '../../utils/capture-error';
import { NavBar } from './nav-bar';
import { navData, NavDataLink, NavDataSection } from './nav-data';
import { NavDrawer } from './nav-drawer';

export function Navigation() {
  // State
  const [data, setData] = React.useState<NavDataSection[]>([]);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response: {
          data: CarrierHeaderLink[];
        } = await API.post('DashboardAPI', '/carriers/header', { body: {} });

        // Filter out children of parent carriers
        const dataFiltered = response.data
          .filter((i) => !i.CarrierCode.startsWith('ap-'))
          .filter((i) => !i.CarrierCode.startsWith('ma-'))
          .filter((i) => !i.CarrierCode.startsWith('ms-'));

        // Order data by Title
        const dataOrdered = orderBy(dataFiltered, 'Title');

        // Format data to match NavDataLink
        const newLinks: NavDataLink[] = [];
        dataOrdered.forEach((i) => {
          newLinks.push({
            title: i.Title,
            to: `/carriers/${i.CarrierCode}`,
            startIcon: <ArrowRightAltIcon fontSize="small" />,
          });
        });

        // Update navigation data
        setData((currentState) =>
          currentState.map((section) => {
            if (section.title === 'Carriers') {
              return {
                ...section,
                links: [...section.links, ...newLinks],
              };
            } else {
              return section;
            }
          })
        );
      } catch (error) {
        captureError({ data: { error } });
      }
    };

    setData(navData);
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <NavDrawer data={data} />

      <NavBar data={data} />
    </React.Fragment>
  );
}
