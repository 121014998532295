import { API } from 'aws-amplify';
import addWeek from 'date-fns/addWeeks';
import endOfWeek from 'date-fns/endOfWeek';
import format from 'date-fns/format';
import startOfWeek from 'date-fns/startOfWeek';
import subWeek from 'date-fns/subWeeks';
import * as Highcharts from 'highcharts';
import React from 'react';
import ActivityContext from '../../contexts/activity-context';
import { AuthContext } from '../../contexts/auth-context';
import { captureError } from '../../utils/capture-error';

export function ActivityState(props: { children: React.ReactNode }) {
  // Context
  const {
    state: { user },
  } = React.useContext(AuthContext);
  // State
  const [salesA, setSalesA] = React.useState({});
  const [salesB, setSalesB] = React.useState({});
  const [recruitingA, setRecruitingA] = React.useState({});
  const [recruitingB, setRecruitingB] = React.useState({});
  const [isHome, setIsHome] = React.useState(false);

  const [savedValues, setSavedValues] = React.useState({
    dials: 0,
    contacts: 0,
    appointments: 0,
    sits: 0,
    submitted_apps: 0,
    submitted_ap: 0,
    referrals: 0,
    money_found: 0,
    door_knocks: 0,
    annuity_apps: 0,
    annuity_ap: 0,
    interviews: 0,
    fastracts: 0,
    new_in_school: 0,
    width_contracts: 0,
    first_submit: 0,
    first_issue_paid: 0,
  });

  const [savedGoals, setSavedGoals] = React.useState({
    dials: 300,
    contacts: 45,
    appointments: 25,
    sits: 20,
    submitted_apps: 15,
    submitted_ap: 10000,
    referrals: 75,
    money_found: 100000,
    door_knocks: 25,
    annuity_apps: 2,
    annuity_ap: 100000,
    interviews: 50,
    fastracts: 10,
    new_in_school: 5,
    width_contracts: 5,
    first_submit: 3,
    first_issue_paid: 2,
  });

  const [values, setValues] = React.useState({
    savedValues,
  });

  const [goals, setGoals] = React.useState({
    savedGoals,
  });

  const handleGoals = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGoals({ ...goals, [e.target.name]: parseInt(e.target.value) || 0 });
  };
  const handleValues = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [e.target.name]: parseInt(e.target.value) || 0,
    });
  };

  // Week Date
  const currentDate = new Date();
  const [weekStart, setWeekStart] = React.useState(
    format(startOfWeek(currentDate), 'MMMM d, yyyy')
  );
  const [weekEnd, setWeekEnd] = React.useState(
    format(endOfWeek(currentDate), 'MMMM d, yyyy')
  );
  const handleBack = () => {
    setWeekStart(format(subWeek(new Date(weekStart), 1), 'MMMM d, yyyy'));
    setWeekEnd(format(subWeek(new Date(weekEnd), 1), 'MMMM d, yyyy'));
  };
  const handleNext = () => {
    setWeekStart(format(addWeek(new Date(weekStart), 1), 'MMMM d, yyyy'));
    setWeekEnd(format(addWeek(new Date(weekEnd), 1), 'MMMM d, yyyy'));
  };

  // GET SAVED GOALS AND VALUES
  React.useEffect(() => {
    const setLoadedData = (data: any) => {
      setSavedValues({
        dials: data.Dials || 0,
        contacts: data.Contacts || 0,
        appointments: data.Appointments || 0,
        sits: data.Sits || 0,
        submitted_apps: data.SubmittedApps || 0,
        submitted_ap: data.SubmittedAP || 0,
        referrals: data.BonusReferrals || 0,
        money_found: data.BonusGoalFoundValue || 0,
        door_knocks: data.BonusDoorKnocks || 0,
        annuity_apps: data.BonusAnnuityApps || 0,
        annuity_ap: data.BonusAnnuityAP || 0,
        interviews: data.Interviews || 0,
        fastracts: data.Fastracts || 0,
        new_in_school: data.NewInSchool || 0,
        width_contracts: data.WidthContracts || 0,
        first_submit: data.FirstSubmit || 0,
        first_issue_paid: data.FirstIssuePaid || 0,
      });
      setSavedGoals({
        dials: data.GoalDials || 300,
        contacts: data.GoalContacts || 45,
        appointments: data.GoalAppointments || 25,
        sits: data.GoalSits || 20,
        submitted_apps: data.GoalSubmittedApps || 15,
        submitted_ap: data.GoalSubmittedAP || 10000,
        referrals: data.GoalBonusReferrals || 75,
        money_found: data.GoalBonusGoalFoundValue || 100000,
        door_knocks: data.GoalBonusDoorKnocks || 25,
        annuity_apps: data.GoalBonusAnnuityApps || 2,
        annuity_ap: data.GoalBonusAnnuityAP || 100000,
        interviews: data.GoalInterviews || 50,
        fastracts: data.GoalFastracts || 10,
        new_in_school: data.GoalNewInSchool || 5,
        width_contracts: data.GoalWidthContracts || 5,
        first_submit: data.GoalFirstSubmit || 3,
        first_issue_paid: data.GoalFirstIssuePaid || 2,
      });
    };

    let promise: Promise<any> | undefined;
    const fetchData = async () => {
      const body = {
        agentno: user.username,
        WeekEnding: format(new Date(weekEnd), 'yyyy-MM-dd HH:mm:ss.SSS'),
      };

      const path = '/crm/contacts/downlineactivity/activityByWeek';
      try {
        promise = API.post('DashboardAPI', path, {
          body,
        });
        const response = await promise;

        if (response.data !== null) {
          setLoadedData(response.data);
        } else {
          setLoadedData('');
        }
      } catch (error) {
        if (!API.isCancel(error)) {
          captureError({ data: { error, request: { body, path } } });
        }
      }
    };
    fetchData();

    return () => {
      if (promise) {
        API.cancel(promise);
      }
    };
  }, [weekEnd, user.username]);

  // SAVE GOALS AND VALUES
  const updateSavedData = async (values: any, goals: any) => {
    const body = {
      Username: user.username,
      WeekEnding: weekEnd,
      Dials: values.dials,
      Contacts: values.contacts,
      Appointments: values.appointments,
      BonusReferrals: values.referrals,
      BonusGoalFoundValue: values.money_found,
      BonusDoorKnocks: values.door_knocks,
      BonusAnnuityApps: values.annuity_apps,
      BonusAnnuityAP: values.annuity_ap,
      Sits: values.sits,
      SubmittedApps: values.submitted_apps,
      SubmittedAP: values.submitted_ap,
      Interviews: values.interviews,
      Fastracts: values.fastracts,
      NewInSchool: values.new_in_school,
      WidthContracts: values.width_contracts,
      FirstSubmit: values.first_submit,
      FirstIssuePaid: values.first_issue_paid,
      GoalAppointments: goals.appointments,
      GoalBonusAnnuityAP: goals.annuity_ap,
      GoalBonusAnnuityApps: goals.annuity_apps,
      GoalBonusDoorKnocks: goals.door_knocks,
      GoalBonusGoalFoundValue: goals.money_found,
      GoalBonusReferrals: goals.referrals,
      GoalContacts: goals.contacts,
      GoalDials: goals.dials,
      GoalFastracts: goals.fastracts,
      GoalFirstIssuePaid: goals.first_issue_paid,
      GoalFirstSubmit: goals.first_submit,
      GoalInterviews: goals.interviews,
      GoalNewInSchool: goals.new_in_school,
      GoalSits: goals.sits,
      GoalSubmittedAP: goals.submitted_ap,
      GoalSubmittedApps: goals.submitted_apps,
      GoalWidthContracts: goals.width_contracts,
    };

    const path = '/crm/contacts/downlineactivity/upsert';

    try {
      await API.post('DashboardAPI', path, {
        body,
      });
    } catch (error) {
      captureError({ data: { error, request: { body, path } } });
    }
  };

  // Sales Gauge - DIALS, CONTACTS, APPOINTMENTS
  React.useEffect(() => {
    setSalesA({
      chart: {
        type: 'solidgauge',
        height: '75%',
        backgroundColor: isHome ? '#fafafa' : '#fff',
        spacing: 0,
      },
      title: {
        text: undefined,
      },

      tooltip: {
        borderWidth: 0,
        backgroundColor: 'none',
        shadow: false,
        style: {
          fontSize: '16px',
        },

        valueSuffix: '%',
        pointFormat:
          '{series.name}<br><span style="font-size:1.35em; color: {point.color}; font-weight: bold;">{point.y}</span>',

        positioner: function (
          labelWidth: number,
          labelHeight: any,
          point: { plotX: number; plotY: number }
        ) {
          return {
            x: point.plotX - labelWidth / 2,
            y: point.plotY - 15,
          };
        },
      },
      pane: {
        startAngle: 0,
        endAngle: 360,
        background: [
          {
            // Track for Dials
            outerRadius: '112%',
            innerRadius: '88%',
            backgroundColor: Highcharts.color('#7A9F3F').setOpacity(0.3).get(),
            borderWidth: 0,
          },
          {
            // Track for Contacts
            outerRadius: '87%',
            innerRadius: '63%',
            backgroundColor: Highcharts.color('#698DC8').setOpacity(0.3).get(),
            borderWidth: 0,
          },
          {
            // Track for Appointments
            outerRadius: '62%',
            innerRadius: '38%',
            backgroundColor: Highcharts.color('#9079B7').setOpacity(0.3).get(),
            borderWidth: 0,
          },
        ],
      },
      yAxis: {
        min: 0,
        max: 100,
        lineWidth: 0,
        tickPositions: [],
      },
      plotOptions: {
        solidgauge: {
          dataLabels: {
            enabled: false,
          },
          linecap: 'round',
          stickyTracking: false,
          rounded: true,
        },
      },
      series: [
        {
          // DIALS
          name: '',
          data: [
            {
              color: '#7A9F3F',
              radius: '112%',
              innerRadius: '88%',
              y: Math.floor((savedValues.dials / savedGoals.dials) * 100),
            },
          ],
        },
        {
          // CONTACTS
          name: '',
          data: [
            {
              color: '#698DC8',
              radius: '87%',
              innerRadius: '63%',
              y: Math.floor((savedValues.contacts / savedGoals.contacts) * 100),
            },
          ],
        },
        {
          // APPOINTMENTS
          name: '',
          data: [
            {
              color: '#9079B7',
              radius: '62%',
              innerRadius: '38%',
              y: Math.floor(
                (savedValues.appointments / savedGoals.appointments) * 100
              ),
            },
          ],
        },
      ],
      credits: {
        enabled: false,
      },
    });
  }, [
    savedValues.dials,
    savedGoals.dials,
    savedValues.contacts,
    savedGoals.contacts,
    savedValues.appointments,
    savedGoals.appointments,
    isHome,
  ]);

  // Sales Gauge - SITS, SUBMITTED APPS, SUBMITTED AP
  React.useEffect(() => {
    setSalesB({
      chart: {
        type: 'solidgauge',
        height: '75%',
        backgroundColor: isHome ? '#fafafa' : '#fff',
        spacing: 0,
      },
      title: {
        text: undefined,
      },
      tooltip: {
        borderWidth: 0,
        backgroundColor: 'none',
        shadow: false,
        style: {
          fontSize: '16px',
        },
        valueSuffix: '%',
        pointFormat:
          '{series.name}<br><span style="font-size:1.35em; color: {point.color}; font-weight: bold">{point.y}</span>',
        positioner: function (
          labelWidth: number,
          labelHeight: number,
          point: { plotX: number; plotY: number }
        ) {
          return {
            x: point.plotX - labelWidth / 2,
            y: point.plotY - 15,
          };
        },
      },
      pane: {
        startAngle: 0,
        endAngle: 360,
        background: [
          {
            // Track for SITS
            outerRadius: '112%',
            innerRadius: '88%',
            backgroundColor: Highcharts.color('#7A9F3F').setOpacity(0.3).get(),
            borderWidth: 0,
          },
          {
            // Track for SUBMITTED APPOINTMENTS
            outerRadius: '87%',
            innerRadius: '63%',
            backgroundColor: Highcharts.color('#698DC8').setOpacity(0.3).get(),
            borderWidth: 0,
          },
          {
            // Track for SUBMITTED AP
            outerRadius: '62%',
            innerRadius: '38%',
            backgroundColor: Highcharts.color('#9079B7').setOpacity(0.3).get(),
            borderWidth: 0,
          },
        ],
      },
      yAxis: {
        min: 0,
        max: 100,
        lineWidth: 0,
        tickPositions: [],
      },
      plotOptions: {
        solidgauge: {
          dataLabels: {
            enabled: false,
          },
          linecap: 'round',
          stickyTracking: false,
          rounded: true,
        },
      },
      series: [
        {
          // SITS
          name: '',
          data: [
            {
              color: '#7A9F3F',
              radius: '112%',
              innerRadius: '88%',
              y: Math.floor((savedValues.sits / savedGoals.sits) * 100),
            },
          ],
        },
        {
          // SUBMITTED APPS
          name: '',
          data: [
            {
              color: '#698DC8',
              radius: '87%',
              innerRadius: '63%',
              y: Math.floor(
                (savedValues.submitted_apps / savedGoals.submitted_apps) * 100
              ),
            },
          ],
        },
        {
          // SUBMITTTED AP
          name: '',
          data: [
            {
              color: '#9079B7',
              radius: '62%',
              innerRadius: '38%',
              y: Math.floor(
                (savedValues.submitted_ap / savedGoals.submitted_ap) * 100
              ),
            },
          ],
        },
      ],
      credits: {
        enabled: false,
      },
    });
  }, [
    savedValues.sits,
    savedGoals.sits,
    savedValues.submitted_apps,
    savedGoals.submitted_apps,
    savedValues.submitted_ap,
    savedGoals.submitted_ap,
    isHome,
  ]);

  // Recruiting Gauge - INTERVIEWS, FASTRACTS, NEW IN SCHOOL
  React.useEffect(() => {
    setRecruitingA({
      chart: {
        type: 'solidgauge',
        height: '75%',
        spacing: 0,
        backgroundColor: isHome ? '#fafafa' : '#fff',
      },
      title: {
        text: undefined,
      },
      tooltip: {
        borderWidth: 0,
        backgroundColor: 'none',
        shadow: false,
        style: {
          fontSize: '16px',
        },
        valueSuffix: '%',
        pointFormat:
          '{series.name}<br><span style="font-size:1.35em; color: {point.color}; font-weight: bold; ">{point.y}</span>',
        positioner: function (
          labelWidth: number,
          labelHeight: number,
          point: { plotX: number; plotY: number }
        ) {
          return {
            x: point.plotX - labelWidth / 2,
            y: point.plotY - 15,
          };
        },
      },
      pane: {
        startAngle: 0,
        endAngle: 360,
        background: [
          {
            // Track for INTERVIEWS
            outerRadius: '112%',
            innerRadius: '88%',
            backgroundColor: Highcharts.color('#7A9F3F').setOpacity(0.3).get(),
            borderWidth: 0,
          },
          {
            // Track for FASTRACTS
            outerRadius: '87%',
            innerRadius: '63%',
            backgroundColor: Highcharts.color('#698DC8').setOpacity(0.3).get(),
            borderWidth: 0,
          },
          {
            // Track for NEW IN SCHOOL
            outerRadius: '62%',
            innerRadius: '38%',
            backgroundColor: Highcharts.color('#9079B7').setOpacity(0.3).get(),
            borderWidth: 0,
          },
        ],
      },
      yAxis: {
        min: 0,
        max: 100,
        lineWidth: 0,
        tickPositions: [],
      },
      plotOptions: {
        solidgauge: {
          dataLabels: {
            enabled: false,
          },
          linecap: 'round',
          stickyTracking: false,
          rounded: true,
        },
      },
      series: [
        {
          // INTERVIEWS
          name: '',
          data: [
            {
              color: '#7A9F3F',
              radius: '112%',
              innerRadius: '88%',
              y: Math.floor(
                (savedValues.interviews / savedGoals.interviews) * 100
              ),
            },
          ],
        },
        {
          // FASTRACTS
          name: '',
          data: [
            {
              color: '#698DC8',
              radius: '87%',
              innerRadius: '63%',
              y: Math.floor(
                (savedValues.fastracts / savedGoals.fastracts) * 100
              ),
            },
          ],
        },
        {
          // NEW IN SCHOOL
          name: '',
          data: [
            {
              color: '#9079B7',
              radius: '62%',
              innerRadius: '38%',
              y: Math.floor(
                (savedValues.new_in_school / savedGoals.new_in_school) * 100
              ),
            },
          ],
        },
      ],
      credits: {
        enabled: false,
      },
    });
  }, [
    savedValues.interviews,
    savedGoals.interviews,
    savedValues.fastracts,
    savedGoals.fastracts,
    savedValues.new_in_school,
    savedGoals.new_in_school,
    isHome,
  ]);

  // Recruiting Gauge - WIDTH CONTRACTS, 1ST SUBMIT, 1ST ISSUE PAID
  React.useEffect(() => {
    setRecruitingB({
      chart: {
        type: 'solidgauge',
        height: '75%',
        spacing: 0,
        backgroundColor: isHome ? '#fafafa' : '#fff',
      },
      title: {
        text: undefined,
      },
      tooltip: {
        borderWidth: 0,
        backgroundColor: 'none',
        shadow: false,
        style: {
          fontSize: '16px',
        },
        valueSuffix: '%',
        pointFormat:
          '{series.name}<br><span style="font-size:1.35em; color: {point.color}; font-weight: bold">{point.y}</span>',

        positioner: function (
          labelWidth: number,
          labelHeight: number,
          point: { plotX: number; plotY: number }
        ) {
          return {
            x: point.plotX - labelWidth / 2,
            y: point.plotY - 15,
          };
        },
      },
      pane: {
        startAngle: 0,
        endAngle: 360,
        background: [
          {
            // Track for WIDTH CONTRACTS
            outerRadius: '112%',
            innerRadius: '88%',
            backgroundColor: Highcharts.color('#7A9F3F').setOpacity(0.3).get(),
            borderWidth: 0,
          },
          {
            // Track for 1ST SUBMIT
            outerRadius: '87%',
            innerRadius: '63%',
            backgroundColor: Highcharts.color('#698DC8').setOpacity(0.3).get(),
            borderWidth: 0,
          },
          {
            // Track for 1ST ISSUE PAID
            outerRadius: '62%',
            innerRadius: '38%',
            backgroundColor: Highcharts.color('#9079B7').setOpacity(0.3).get(),
            borderWidth: 0,
          },
        ],
      },
      yAxis: {
        min: 0,
        max: 100,
        lineWidth: 0,
        tickPositions: [],
      },
      plotOptions: {
        solidgauge: {
          dataLabels: {
            enabled: false,
          },
          linecap: 'round',
          stickyTracking: false,
          rounded: true,
        },
      },
      series: [
        {
          // WIDTH CONTRACTS
          name: '',
          data: [
            {
              color: '#7A9F3F',
              radius: '112%',
              innerRadius: '88%',
              y: Math.floor(
                (savedValues.width_contracts / savedGoals.width_contracts) * 100
              ),
            },
          ],
        },
        {
          // 1ST SUBMIT
          name: '',
          data: [
            {
              color: '#698DC8',
              radius: '87%',
              innerRadius: '63%',
              y: Math.floor(
                (savedValues.first_submit / savedGoals.first_submit) * 100
              ),
            },
          ],
        },
        {
          // 1ST ISSUE PAID
          name: '',
          data: [
            {
              color: '#9079B7',
              radius: '62%',
              innerRadius: '38%',
              y: Math.floor(
                (savedValues.first_issue_paid / savedGoals.first_issue_paid) *
                  100
              ),
            },
          ],
        },
      ],
      credits: {
        enabled: false,
      },
    });
  }, [
    savedValues.width_contracts,
    savedGoals.width_contracts,
    savedValues.first_submit,
    savedGoals.first_submit,
    savedValues.first_issue_paid,
    savedGoals.first_issue_paid,
    isHome,
  ]);

  return (
    <ActivityContext.Provider
      value={{
        salesA,
        salesB,
        recruitingA,
        recruitingB,
        values,
        savedValues,
        goals,
        savedGoals,
        setGoals,
        setValues,
        handleValues,
        handleGoals,
        setSavedValues,
        setSavedGoals,
        weekEnd,
        weekStart,
        handleBack,
        handleNext,
        updateSavedData,
        setIsHome,
      }}
    >
      {props.children}
    </ActivityContext.Provider>
  );
}
