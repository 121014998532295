import { API } from 'aws-amplify';
import React from 'react';
import { AuthContext } from '../../contexts/auth-context';
import { PopUpData } from '../../types';
import { captureError } from '../../utils/capture-error';
import { customPopUps } from './pop-up-data';
import { PopUpItem } from './pop-up-item';
import { getPopUp } from './pop-up-utils';

export function Popups() {
  // Context
  const {
    state: { user },
  } = React.useContext(AuthContext);
  // State
  const [content, setContent] = React.useState<PopUpData | null>(null);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response: {
          data: PopUpData[];
        } = await API.post('DashboardAPI', '/dashboard/popup', {});
        const data = [...customPopUps, ...response.data];
        const newPopUp = await getPopUp({ data, username: user.username });
        setContent(newPopUp);
      } catch (error) {
        captureError({ data: { error } });
      }
    };

    fetchData();
  }, [user]);

  if (content) {
    return (
      <PopUpItem
        data={content}
        username={user.username}
        onClose={() => setContent(null)}
      />
    );
  } else {
    return null;
  }
}
